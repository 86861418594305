import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function RiversAndRoads() {
  return (
    <Layout>
      <Helmet title="Rivers & Roads | The Head & The Heart" />

      <h1>Rivers & Roads</h1>

      <h2>The Head & The Heart</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <b>C Am C</b>
          </p>
          <p>
            <b>Am F C</b>
          </p>
        </Verse>
        <Verse>
          <p>
            A <Ch>C</Ch>year from now, we'll <Ch>Am</Ch>all be <Ch>C</Ch>gone
          </p>
          <p>
            All our <Ch>Am</Ch>friends will <Ch>F</Ch>move <Ch>C</Ch>away
          </p>
          <p>
            And they're <Ch>Am</Ch>goin' to <Ch>F</Ch>better <Ch>C</Ch>places
          </p>
          <p>
            But our <Ch>Am</Ch>friends will <Ch>F</Ch>be gone <Ch>C</Ch>away
          </p>
        </Verse>
        <Verse>
          <p>
            <Ch>C</Ch>Nothin' is as <Ch>Am</Ch>it has <Ch>C</Ch>been
          </p>
          <p>
            And I <Ch>Am</Ch>miss your <Ch>C</Ch>face like <Ch>C</Ch>hell
          </p>
          <p>
            And I <Ch>Am</Ch>guess it's <Ch>F</Ch>just as <Ch>C</Ch>well
          </p>
          <p>
            But I <Ch>Am</Ch>miss your <Ch>F</Ch>face like <Ch>C</Ch>hell
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>C</Ch>Ohh, <Ch>Am</Ch>oooh, <Ch>C</Ch>ooh, ooh
          </p>
          <p>
            <Ch>Am</Ch>Ohh, <Ch>F</Ch>oooh, <Ch>C</Ch>ooh, ooh
          </p>
        </Chorus>
        <Verse>
          <p>
            Been <Ch>C</Ch>talkin' 'bout the <Ch>Am</Ch>way things <Ch>C</Ch>
            change
          </p>
          <p>
            And my <Ch>Am</Ch>family <Ch>F</Ch>lives in a <Ch>C</Ch>different
            state
          </p>
          <p>
            If you <Ch>Am</Ch>don't know <Ch>F</Ch>what to <Ch>C</Ch>make of
            this
          </p>
          <p>
            Then <Ch>Am</Ch>we will <Ch>F</Ch>not <Ch>C</Ch>relate
          </p>
          <p>
            So if you <Ch>Am</Ch>don't know<Ch>F</Ch> what to <Ch>C</Ch>make of
            this
          </p>
          <p>
            Then <Ch>Am</Ch>we will <Ch>F</Ch>not <Ch>C</Ch>relate
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>C</Ch>Ohh, <Ch>Am</Ch>oooh, <Ch>C</Ch>ooh, ooh
          </p>
          <p>
            <Ch>Am</Ch>Ohh, <Ch>F</Ch>oooh, <Ch>C</Ch>ooh, ooh
          </p>
        </Chorus>
        <Bridge>
          <p>
            <Ch>C</Ch>Rivers and roads
          </p>
          <p>
            <Ch>Am</Ch>Rivers and <Ch>C</Ch>roads
          </p>
          <p>
            <Ch>Am</Ch>Rivers 'til <Ch>F</Ch>I reach <Ch>C</Ch>you
          </p>
        </Bridge>
        <Chorus>
          <p>
            <Ch>C</Ch>Ohh, <Ch>Am</Ch>oooh, <Ch>C</Ch>ooh, ooh
          </p>
          <p>
            <Ch>Am</Ch>Ohh, <Ch>F</Ch>oooh, <Ch>C</Ch>ooh, ooh
          </p>
        </Chorus>
        <Bridge>
          <p>
            <Ch>C</Ch>Rivers and roads
          </p>
          <p>
            <Ch>Am</Ch>Rivers and <Ch>C</Ch>roads
          </p>
          <p>
            <Ch>Am</Ch>Rivers 'til <Ch>F</Ch>I reach <Ch>C</Ch>you
          </p>
        </Bridge>
      </Columns>
    </Layout>
  );
}
